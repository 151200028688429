import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Output, ViewChild } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { KanbanItemDto } from "@apiModels/kanbanItemDto";
import { ProjectTaskDto } from "@apiModels/projectTaskDto";
import { ProjectTaskNoteDto } from "@apiModels/projectTaskNoteDto";
import { ActivityService } from "@globals/services/activity.service";
import { kanbanPriorityItems, kanbanStatusItems, kanbanTypeItems, userItems } from "@shared/interfaces-and-enums/shared-data";
import { WindowSessionStorageNames } from "@shared/variables-and-functions/WindowSessionStorageNames";
import { RichTextEditorComponent } from "@syncfusion/ej2-angular-richtexteditor";
import { ConfirmationService, MessageService, SelectItem } from "primeng/api";

@Component({
  selector: "app-project-task-dialog",
  templateUrl: "./project-task-dialog.component.html",
  styleUrls: ["./project-task-dialog.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProjectTaskDialogComponent implements OnChanges {
  @ViewChild("richTextEditorBody") public richTextEditorBody!: RichTextEditorComponent;
  @ViewChild("richTextEditorNewNoteBody") public richTextEditorNewNoteBody!: RichTextEditorComponent;

  @Input() isVisible: boolean = false;
  @Input() taskId: number | null = null;
  @Input() projectTask: ProjectTaskDto | null = null;
  @Output() visibilityChange: EventEmitter<KanbanItemDto | null> = new EventEmitter();

  public userId = +window.sessionStorage.getItem(WindowSessionStorageNames.userId);
  public selectedResponsibleItem: SelectItem | null = null;
  public selectedStatusItem: SelectItem | null = null;
  public selectedTypeItem: SelectItem | null = null;
  public selectedPriorityItem: SelectItem | null = null;
  public tags: string[] | undefined;
  public name: string | undefined;
  public selectedTask: ProjectTaskDto | null = null;
  public newNoteBody: string = "";
  public notes: ProjectTaskNoteDto[] = [];
  public userItems = userItems;
  public kanbanStatusItems = kanbanStatusItems;
  public mentionUsers: { [key: string]: Object }[] = [];
  public kanbanTypeItems = kanbanTypeItems;
  public kanbanPriorityItems = kanbanPriorityItems;
  public mentionFields: Object = { text: "name", value: "id" };

  constructor(
    private activityService: ActivityService,
    private cd: ChangeDetectorRef,
    private sanitizer: DomSanitizer,
    private confirmationService: ConfirmationService,
    private messageService: MessageService
  ) {
    this.mentionUsers = this.userItems.map(user => ({
      id: user.value,
      name: user.label
    }));
  }

  public hideRichTextEditorBodyToolbar(): void {
    this.richTextEditorBody.toolbarSettings.enable = false;
  }

  public showRichTextEditorBodyToolbar(): void {
    this.richTextEditorBody.toolbarSettings.enable = true;
  }
  public hideRichTextEditorNewNoteBodyToolbar(): void {
    this.richTextEditorNewNoteBody.toolbarSettings.enable = false;
  }

  public showRichTextEditorNewNoteBodyToolbar(): void {
    this.richTextEditorNewNoteBody.toolbarSettings.enable = true;
  }

  ngOnChanges() {
    if (this.taskId && this.taskId > 0) {
      this.loadTaskData(this.taskId);
      this.cd.detectChanges();
    } else {
      this.loadTaskData(0);
      this.cd.detectChanges();
    }
  }

  getSafeHtml(noteBody: string) {
    return this.sanitizer.bypassSecurityTrustHtml(noteBody);
  }

  loadTaskData(id: number) {
    if (id === 0) {
      // Trigger change detection to resolve ExpressionChangedAfterItHasBeenCheckedError
      this.selectedTask = this.projectTask;
      this.setTaskDetails(this.selectedTask);
      this.cd.detectChanges();
    } else {
      this.activityService.getProjectTaskById(id).subscribe(
        (task: ProjectTaskDto) => {
          this.selectedTask = task;
          this.setTaskDetails(task);
          // Trigger change detection to resolve ExpressionChangedAfterItHasBeenCheckedError
          this.cd.detectChanges();
        },
        error => {
          console.error("Error fetching task data:", error);
        }
      );
    }
  }

  private setTaskDetails(task: ProjectTaskDto) {
    this.selectedResponsibleItem = this.userItems.find(user => user.value === task.assigneeUserId) || null;
    this.selectedStatusItem = this.kanbanStatusItems.find(status => status.value === task.status) || null;
    this.selectedTypeItem = this.kanbanTypeItems.find(type => type.value === task.type) || null;
    this.selectedPriorityItem = this.kanbanPriorityItems.find(priority => priority.value === task.priority) || null;
    this.tags = task.tags ? task.tags.split(",") : [];
    this.notes = task.notes || [];
    this.name = task.name || "";
  }

  // Get username by ID
  getUserName(userId: number): string {
    const user = this.userItems.find(u => u.value === userId);
    return user ? user.label : "Unknown";
  }

  addNote() {
    if (this.newNoteBody?.trim()) {
      const newNote: ProjectTaskNoteDto = {
        id: 0, // Temporarily set to 0, backend will update
        projectTaskId: this.taskId!, // Use the current taskId
        dateCreatedUtc: new Date().toISOString(),
        createdByUserId: this.userId,
        body: this.newNoteBody
      };

      this.activityService.updateProjectTaskNote(newNote).subscribe(
        () => {
          this.loadTaskData(this.taskId!);
          this.newNoteBody = "";
          this.cd.markForCheck();
        },
        error => {
          console.error("Error adding note:", error);
        }
      );
    }
  }

  deleteNote(note: ProjectTaskNoteDto) {
    const confirmDelete = window.confirm("Are you sure you want to delete this note?");
    if (confirmDelete) {
      note.dateDeletedUtc = new Date().toISOString();
      note.deletedByUserId = this.userId;

      this.activityService.updateProjectTaskNote(note).subscribe(
        () => {
          this.loadTaskData(this.taskId!);
          this.cd.markForCheck();
        },
        error => {
          console.error("Error deleting note:", error);
        }
      );
    }
  }

  startEdit(note: ProjectTaskNoteDto) {
    note.isEditing = true;
  }

  saveNoteEdit(note: ProjectTaskNoteDto) {
    note.isEditing = false;

    this.activityService.updateProjectTaskNote(note).subscribe(
      () => {
        console.log("Note updated successfully");
      },
      error => {
        console.error("Error updating note:", error);
      }
    );
  }

  cancelNoteEdit(note: ProjectTaskNoteDto) {
    note.isEditing = false;
  }

  saveTask() {
    this.addNote();

    if (this.selectedTask) {
      this.selectedTask.assigneeUserId = this.selectedResponsibleItem?.value;
      if (this.selectedStatusItem) this.selectedTask.status = this.selectedStatusItem?.value;
      if (this.selectedTypeItem) this.selectedTask.type = this.selectedTypeItem?.value;
      if (this.selectedPriorityItem) this.selectedTask.priority = this.selectedPriorityItem?.value;
      this.selectedTask.tags = this.tags?.join(",") || "";
      this.selectedTask.name = this.name || "";

      this.activityService.updateProjectTask(this.selectedTask).subscribe(
        (dto: KanbanItemDto) => {
          console.log("Task saved successfully");
          this.closeDialog(dto);
        },
        error => {
          console.error("Error saving task:", error);
        }
      );
    }
  }

  closeDialog(updatedTask: KanbanItemDto) {
    this.isVisible = false;
    //this.visibilityChange.emit(this.isVisible);
    this.visibilityChange.emit(updatedTask);
    //this.taskUpdated.emit(updatedTask);
  }
}
