import { SelectItem } from "primeng/api";

export const activityTypeItems: SelectItem[] = [
  { label: "Ikke angivet", value: -1 },
  { label: "Telefon møde", value: 1 },
  { label: "Kunde møde", value: 2 },
  { label: "Kunde besøg", value: 3 },
  { label: "Opfølgning", value: 4 },
  { label: "Teams møde", value: 5 },
  { label: "Teori undervisning", value: 6 },
  { label: "Praktik undervisning", value: 7 },
  { label: "Udstilling/messe", value: 5 }
];

export const categoryItems: SelectItem[] = [
  { label: "Ikke angivet", value: -1, icon: "" },
  { label: "Grøn", value: 1, icon: "green" },
  { label: "Orange", value: 2, icon: "yellow" },
  { label: "Lysegrøn", value: 3, icon: "lime" },
  { label: "Rød", value: 4, icon: "red" },
  { label: "Lilla", value: 5, icon: "purple" }
];

export const activityTypeItemsWithAll: SelectItem[] = [{ label: "Alle", value: -1 }, ...activityTypeItems];

export interface UserInfo {
  id: number;
  email: string;
  fullName: string;
  firstName: string;
  initials: string;
  colorHex: string;
}

export const userInfos: { [key: string]: UserInfo } = {
  Magnus: {
    id: 20,
    email: "mt@neeobovis.dk",
    fullName: "Magnus Timmermann",
    firstName: "Magnus",
    initials: "MT",
    colorHex: "#0000FF" // Blue
  },
  Glennie: {
    id: 2,
    email: "gc@neeobovis.dk",
    fullName: "Glennie Christensen",
    firstName: "Glennie",
    initials: "GC",
    colorHex: "#008000" // Green
  },
  Rebecca: {
    id: 24,
    email: "rs@neeobovis.dk",
    fullName: "Rebecca Sachse",
    firstName: "Rebecca",
    initials: "RS",
    colorHex: "#FF0000" // Red
  },
  Nina: {
    id: 21,
    email: "nj@neeobovis.dk",
    fullName: "Nina Jebens",
    firstName: "Nina",
    initials: "NJ",
    colorHex: "#800080" // Purple
  },
  Niels: {
    id: 26,
    email: "ns@neeobovis.dk",
    fullName: "Niels Schultz",
    firstName: "Niels",
    initials: "NS",
    colorHex: "#A52A2A" // Brown
  },
  Claus: {
    id: 25,
    email: "ce@neeobovis.dk",
    fullName: "Claus Elmann",
    firstName: "Claus",
    initials: "CE",
    colorHex: "#FFA500" //Orange
  }
};
