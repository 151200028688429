<form *ngIf="mainForm" [formGroup]="mainForm">
  <div class="grid">
    <div class="col-12">
      <div class="card">
        <div class="p-fluid p-formgrid grid">
          <!-- title -->
          <div class="field col-12 md:col-6">
            <label for="title" class="block bold-label">Titel</label>
            <input formControlName="title" type="text" pInputText id="title" aria-describedby="title-help" autocomplete="one-time-code" />
            <div *ngIf="title.errors && (title.dirty || title.touched)" class="p-error block">
              <div *ngIf="title.errors?.required">Påkrævet</div>
            </div>
          </div>

          <!-- place -->
          <div class="field col-12 md:col-6">
            <label for="place" class="block bold-label">Sted</label>
            <input formControlName="place" type="text" pInputText id="place" aria-describedby="place-help" autocomplete="one-time-code" />
          </div>

          <!-- activityAssignments -->
          <div class="field col-12 md:col-6">
            <label for="activityAssignments" class="block bold-label">Tildelte brugere</label>
            <p-multiSelect formControlName="activityAssignments" [options]="userItems" dataKey="value" optionLabel="label" defaultLabel="Vælg brugere"></p-multiSelect>
          </div>

          <!-- isReminderEnabled -->
          <div class="field-checkbox col-1">
            <p-checkbox formControlName="isReminderEnabled" id="isReminderEnabled" binary="true"></p-checkbox>
            <label for="isReminderEnabled" class="block bold-label">Påmind</label>
          </div>
          <!-- createInCalender -->
          <div class="field-checkbox col-1">
            <p-checkbox formControlName="createInCalender" id="createInCalender" binary="true"></p-checkbox>
            <label for="createInCalender" class="block bold-label">Kalender</label>
          </div>

          <!-- inTeams -->
          <div class="field-checkbox col-1">
            <p-checkbox formControlName="inTeams" id="inTeams" binary="true"></p-checkbox>
            <label for="inTeams" class="block bold-label">I Teams</label>
          </div>

          <!-- description -->
          <div class="field col-12 md:col-12">
            <label for="description" class="block bold-label">Beskrivelse</label>
            <textarea formControlName="description" pInputTextarea id="description" rows="6"></textarea>
          </div>

          <div class="field col-12 md:col-4">
            <div class="followup-container">
              <label for="plannedStartDay" class="bold-label">Planlagt start</label>
              <div class="date-time-wrapper">
                <p-calendar
                  formControlName="plannedStartDay"
                  id="plannedStartDay"
                  placeholder="Vælg en dato"
                  dateFormat="dd-mm-yy"
                  [yearNavigator]="true"
                  yearRange="2020:2040"
                  appendTo="body"
                ></p-calendar>
                <p-dropdown formControlName="plannedStartTime" id="plannedStartTime" [options]="timeIntervals" placeholder="Vælg tidspunkt" appendTo="body"></p-dropdown>
              </div>
            </div>
          </div>
          <!-- Type -->
          <div class="field col-12 md:col-3">
            <label for="activityTypeId" class="block bold-label">Aktivitet</label>
            <p-dropdown formControlName="activityTypeId" optionLabel="label" optionValue="value" [options]="activityTypeItems" scrollHeight="400px" [style]="{ 'min-width': '12rem' }"></p-dropdown>
          </div>

          <!-- Kategori -->
          <div class="field col-12 md:col-2">
            <label for="categoryId" class="block bold-label">Kategori</label>
            <p-dropdown formControlName="categoryId" optionLabel="label" optionValue="value" [options]="categoryItems" scrollHeight="400px" [style]="{ 'min-width': '12rem' }"></p-dropdown>
          </div>

          <!-- dateReminder -->
          <div class="field col-12 md:col-2">
            <label for="dateReminder" class="block bold-label">Påmindelsesdato</label>
            <p-calendar
              formControlName="dateReminder"
              id="dateReminder"
              dateFormat="dd-mm-yy"
              [yearNavigator]="true"
              yearRange="2020:2040"
              appendTo="body"
              [showTime]="true"
              [hourFormat]="24"
            ></p-calendar>
            <div *ngIf="dateReminder.errors && (dateReminder.dirty || dateReminder.touched)" class="p-error block">
              <div *ngIf="dateReminder.errors?.required">Påkrævet</div>
            </div>
          </div>

          <div class="field col-12 md:col-4">
            <div class="followup-container">
              <label for="plannedEndDay" class="bold-label">Planlagt slut <span *ngIf="plannedEndDay.errors?.dateInvalid" class="p-error">Slut må ikke være før start</span></label>
              <div class="date-time-wrapper">
                <p-calendar
                  formControlName="plannedEndDay"
                  id="plannedEndDay"
                  placeholder="Vælg en dato"
                  dateFormat="dd-mm-yy"
                  [yearNavigator]="true"
                  yearRange="2020:2040"
                  appendTo="body"
                ></p-calendar>
                <p-dropdown formControlName="plannedEndTime" id="plannedEndTime" [options]="timeIntervals" placeholder="Vælg tidspunkt" appendTo="body"></p-dropdown>
              </div>
            </div>
          </div>

          <div class="field-checkbox col-2">
            <p-checkbox formControlName="isAllDay" id="isAllDay" binary="true" (onChange)="onAllDayChange($event)"></p-checkbox>
            <label for="isAllDay" class="block bold-label">Hele dagen</label>
          </div>

          <div class="field col-12 md:col-2">
            <label for="dateCompleted" class="block bold-label"> Færdiggjort </label>
            <p-calendar
              formControlName="dateCompleted"
              id="dateCompleted"
              placeholder="Vælg en dato"
              dateFormat="dd-mm-yy"
              [yearNavigator]="true"
              yearRange="2020:2040"
              appendTo="body"
              [showTime]="true"
              [hourFormat]="24"
            ></p-calendar>

            <div *ngIf="dateCompleted.errors && (dateCompleted.dirty || dateCompleted.touched)" class="p-error block">
              <div *ngIf="dateCompleted.errors?.required">Påkrævet</div>
            </div>
          </div>

          <div *ngIf="plannedStartDay.value" class="field col-12">
            <label for="recurrenceRule" class="block bold-label">Gentagelse</label>
            <ejs-recurrenceeditor (change)="onRecurrenceChange($event)" [value]="recurrenceRule"></ejs-recurrenceeditor>
          </div>

          <div class="field col-12">
            <div class="formgroup-inline">
              <div class="field">
                <button
                  pButton
                  pRipple
                  type="button"
                  [label]="selectedActivity.id === 0 ? 'Opret' : 'Gem'"
                  class="p-button-raised"
                  [title]="mainForm.valid ? 'Gem dine indtastede data' : 'Deaktiveret, indtil formulardataene er gyldige'"
                  [disabled]="mainForm.pristine"
                  (click)="saveItem()"
                ></button>
              </div>

              <div class="field">
                <button pButton pRipple type="button" class="p-button-outlined p-button-secondary" label="Luk" title="Luk" (click)="close(selectedActivity)"></button>
              </div>

              <div *ngIf="selectedActivity.id !== 0" class="field">
                <button type="button" pButton label="Slet" icon="pi pi-trash" class="p-button-danger" [disabled]="!mainForm.pristine" (click)="deleteItem()"></button>
              </div>

              <div *ngIf="selectedActivity.id !== 0 && mainForm.pristine && selectedActivity.activityTypeId == 6" class="field">
                <button pButton pRipple type="button" class="p-button-outlined p-button-secondary" label="Opret praksis" title="Opret praksis" (click)="createPractical()"></button>
              </div>
            </div>

            <div *ngIf="userId === 25" class="field col-12">
              <div class="field">
                <button pButton pRipple class="p-button-outlined p-button-secondary" type="button" label="Test form for validation in console" (click)="getFormValidationErrors()"></button>
              </div>
              <br />Dirty: {{ mainForm.dirty }} <br />Touched: {{ mainForm.touched }}<br />Pristine: {{ mainForm.pristine }} <br />Valid: {{ mainForm.valid }} <br />Values:
              {{ mainForm.value | json }}
              <p class="field-error" [@fadeIn] *ngIf="showFormErrorMessage" translate>errorMessages.OneOrMoreFieldsMissing</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <p-confirmDialog header="Bekræft sletning" icon="pi pi-exclamation-triangle" [style]="{ width: '50vw' }" [baseZIndex]="10000"></p-confirmDialog>
  <div class="alert alert-danger" *ngIf="errorMessage">{{ errorMessage }}</div>
</form>
