<form *ngIf="mainForm" [formGroup]="mainForm">
  <div class="grid">
    <div class="col-12">
      <div class="card">
        <!-- <h3 class="card-header">Data fra CHR</h3> -->
        <div class="p-fluid p-formgrid grid">
          <div class="field col-12 md:col-3">
            <label for="chrNr" class="block bold-label">Chr nummer</label>
            <p id="chrNr">{{ selectedCompany?.chrNr }}</p>
          </div>

          <div *ngIf="selectedCompany?.chrNr != selectedCompany?.besNr" class="field col-12 md:col-3">
            <label for="chrNr" class="block bold-label">Besætnings nr</label>
            <p id="chrNr">{{ selectedCompany?.besNr }}</p>
          </div>

          <div class="field col-12 md:col-3">
            <label for="brugerNavn" class="block bold-label">Navn</label>
            <p id="ejerNavn">{{ selectedCompany?.brugerNavn }}</p>
          </div>

          <div *ngIf="selectedCompany?.brugerNavn != selectedCompany?.ejerNavn" class="field col-12 md:col-3">
            <label for="ejerNavn" class="block bold-label">Ejer</label>
            <p id="ejerNavn">{{ selectedCompany?.ejerNavn }}</p>
          </div>

          <div class="field col-12 md:col-3">
            <label for="cvrNummer" class="block bold-label">Cvr nummer</label>
            <p id="cvrNummer">{{ selectedCompany?.cvrNummer }}</p>
          </div>

          <div class="field col-12 md:col-3">
            <label for="adresse" class="block bold-label">Adresse</label>
            <p id="adresse">{{ selectedCompany?.adresse }}</p>
          </div>

          <div class="field col-12 md:col-3">
            <label for="postNummer" class="block bold-label">Post Nr</label>
            <p id="postNummer">{{ selectedCompany?.postNummer }}</p>
          </div>

          <div class="field col-12 md:col-3">
            <label for="postDistrikt" class="block bold-label">Postdistrikt</label>
            <p id="postDistrikt">{{ selectedCompany?.postDistrikt }}</p>
          </div>

          <div class="field col-12 md:col-3">
            <label for="region" class="block bold-label">Region</label>
            <p id="region">{{ selectedCompany?.region }}</p>
          </div>

          <div class="field col-12 md:col-3">
            <label for="koer" class="block bold-label">Besætningstype</label>
            <p id="koer">{{ selectedCompany?.besaetningstype }}</p>
          </div>

          <div class="field col-12 md:col-3">
            <label for="koer" class="block bold-label">Køer</label>
            <p id="koer">{{ selectedCompany?.koer }}</p>
          </div>
          <div class="field col-12 md:col-3">
            <label for="kvier" class="block bold-label">Kvier</label>
            <p id="kvier">{{ selectedCompany?.kvier }}</p>
          </div>
          <div class="field col-12 md:col-3">
            <label for="handyr" class="block bold-label">Handyr</label>
            <p id="handyr">{{ selectedCompany?.handyr }}</p>
          </div>

          <div *ngIf="selectedCompany?.cvrData" class="field col-12 md:col-12">
            <label for="cvrData" class="block bold-label">Kontaktoplysninger fra CVR</label>
            <p id="cvrData">{{ selectedCompany?.cvrData }}</p>
          </div>

          <div *ngIf="selectedCompany?.adresseTelefonData" class="field col-12 md:col-12">
            <label for="cvrData" class="block bold-label">Telefonoplysninger fra adressen</label>
            <p id="adresseTelefonData">{{ selectedCompany?.adresseTelefonData }}</p>
          </div>

          <div *ngIf="selectedCompany?.ejerTelefonData" class="field col-12 md:col-12">
            <label for="ejerTelefonData" class="block bold-label"> Telefonoplysninger fra ejer adressen</label>
            <p id="ejerTelefonData">{{ selectedCompany?.ejerTelefonData }}</p>
          </div>

          <!-- <div class="field col-12 md:col-12">
            <label for="cvrData" class="block bold-label">Teledata</label>
            <p *ngIf="selectedCompany?.cvrData" id="handyr">cvrData<br />{{ selectedCompany?.cvrData }}</p>
            <p *ngIf="selectedCompany?.adresseTelefonData" id="adresseTelefonData">Telefondata adresse<br />{{ selectedCompany?.adresseTelefonData }}</p>
            <p *ngIf="selectedCompany?.ejerTelefonData" id="handyr">Telefondata ejer adresse<br />{{ selectedCompany?.ejerTelefonData }}</p>
          </div> -->
        </div>
      </div>

      <div class="card">
        <!-- <h3 class="card-header">Oplysninger</h3> -->
        <div class="p-fluid p-formgrid grid">
          <!-- ansvarligBrugerId -->
          <div class="field col-12 md:col-3">
            <label for="ansvarligBrugerId" class="block bold-label">Ansvarlig</label>
            <p-dropdown
              formControlName="ansvarligBrugerId"
              optionLabel="label"
              dataKey="value"
              [options]="userItems"
              [(ngModel)]="farmUserItem"
              scrollHeight="400px"
              [style]="{ 'min-width': '12rem' }"
              id="ansvarligBrugerId"
              aria-describedby="ansvarligBrugerId-help"
            ></p-dropdown>
            <div *ngIf="ansvarligBrugerId.errors && (ansvarligBrugerId.dirty || ansvarligBrugerId.touched)" class="p-error block">
              <div *ngIf="ansvarligBrugerId.errors?.required">Påkrævet</div>
              <div *ngIf="ansvarligBrugerId.errors?.digitsOnly">Kun hel tal</div>
            </div>
          </div>

          <!-- contactName -->
          <div class="field col-12 md:col-3">
            <label for="contactName" class="block bold-label">Kontakt navn</label>
            <input formControlName="contactName" type="text" pInputText id="contactName" aria-describedby="contactName-help" autocomplete="one-time-code" />
            <div *ngIf="contactName.errors && (contactName.dirty || contactName.touched)" class="p-error block">
              <div *ngIf="contactName.errors?.required">Påkrævet</div>
              <div *ngIf="contactName.errors?.digitsOnly">Kun hel tal</div>
            </div>
          </div>
          <!-- contactPhone -->
          <div class="field col-12 md:col-3">
            <label for="contactPhone" class="block bold-label">Kontakt telefon</label>
            <input formControlName="contactPhone" type="text" pInputText id="contactPhone" aria-describedby="contactPhone-help" autocomplete="one-time-code" />
            <div *ngIf="contactPhone.errors && (contactPhone.dirty || contactPhone.touched)" class="p-error block">
              <div *ngIf="contactPhone.errors?.required">Påkrævet</div>
              <div *ngIf="contactPhone.errors?.digitsOnly">Kun hel tal</div>
            </div>
          </div>
          <!-- contactEmail -->
          <div class="field col-12 md:col-3">
            <label for="contactEmail" class="block bold-label">Kontakt email</label>
            <input formControlName="contactEmail" type="email" pInputText id="contactEmail" aria-describedby="contactEmail-help" autocomplete="one-time-code" />
            <div *ngIf="contactEmail.errors && (contactEmail.dirty || contactEmail.touched)" class="p-error block">
              <div *ngIf="contactEmail.errors?.email && contactEmail.touched">Invalid email</div>
              <div *ngIf="contactEmail.errors?.required">Påkrævet</div>
              <div *ngIf="contactEmail.errors?.digitsOnly">Kun hel tal</div>
            </div>
          </div>

          <!-- status -->
          <div class="field col-12 md:col-3">
            <label for="status" class="block bold-label">Lead status</label>
            <p-dropdown
              formControlName="status"
              optionLabel="label"
              dataKey="value"
              [options]="statusFilterItems"
              [(ngModel)]="farmLeadStatusItem"
              scrollHeight="400px"
              [style]="{ 'min-width': '12rem' }"
              id="status"
              aria-describedby="status-help"
            ></p-dropdown>
            <div *ngIf="status.errors && (status.dirty || status.touched)" class="p-error block">
              <div *ngIf="status.errors?.required">Påkrævet</div>
              <div *ngIf="status.errors?.digitsOnly">Kun hel tal</div>
            </div>
          </div>

          <!-- mejerier -->
          <div class="field col-12 md:col-3">
            <label for="mejerier" class="block bold-label">Mejerier</label>
            <input formControlName="mejerier" type="text" pInputText id="mejerier" aria-describedby="mejerier-help" />
            <div *ngIf="mejerier.errors && (mejerier.dirty || mejerier.touched)" class="p-error block">
              <div *ngIf="mejerier.errors?.required">Påkrævet</div>
              <div *ngIf="mejerier.errors?.digitsOnly">Kun hel tal</div>
            </div>
          </div>

          <div class="field col-12 md:col-3">
            <label for="race" class="block bold-label">Race</label>
            <input formControlName="race" type="text" pInputText id="race" aria-describedby="race-help" />
            <div *ngIf="race.errors && (race.dirty || race.touched)" class="p-error block">
              <div *ngIf="race.errors?.required">Påkrævet</div>
              <div *ngIf="race.errors?.digitsOnly">Kun hel tal</div>
            </div>
          </div>

          <div class="field col-12 md:col-12">
            <label for="noter" class="block bold-label">Noter</label>
            <textarea id="noter" formControlName="noter" type="text" aria-describedby="noter-help" placeholder="Angiv noter" [rows]="3" pInputTextarea autoResize="autoResize"></textarea>
            <div *ngIf="noter.errors && (noter.dirty || noter.touched)" class="p-error block"></div>
          </div>

          <div class="field col-12">
            <div class="formgroup-inline">
              <div class="field">
                <button
                  pButton
                  pRipple
                  type="button"
                  [label]="'Gem'"
                  class="p-button-raised"
                  [title]="mainForm.valid ? 'Gem dine indtastede data' : 'Deaktiveret, indtil formulardataene er gyldige'"
                  [disabled]="mainForm.pristine"
                  (click)="saveItem()"
                ></button>
              </div>
              <div class="field">
                <button pButton pRipple type="button" class="p-button-outlined p-button-secondary" label="Luk" title="Luk" (click)="close(selectedCompany)"></button>
              </div>

              <!-- <div class="field">
                <p-splitButton label="Aktioner" icon="pi pi-cog" [model]="commands"></p-splitButton>
              </div> -->

              <div class="field">
                <button
                  pButton
                  pRipple
                  type="button"
                  [label]="'Opret som kunde'"
                  class="p-button-raised"
                  [title]="'Opret som kunde'"
                  [disabled]="mainForm.dirty || selectedCompany?.customerId"
                  (click)="confirmCreateCustomer()"
                ></button>
              </div>

              <div class="field">
                <a
                  href="https://app-eu1.hubspot.com/contacts/144491104/company/{{ selectedCompany.hubspotCompanyId }}"
                  (click)="openInNamedWindow($event, 'HubspotWindow')"
                  target="HubspotWindow"
                  class="hubspot-link"
                  >Åbn i Hubspot</a
                >
              </div>

              <div class="field"><button class="ml-5 mr-2" [disabled]="userId !== 25" pButton type="button" [label]="'Opret Email' | translate" (click)="createMail()"></button></div>
              <div class="field"><button class="ml-5" [disabled]="userId !== 25" pButton type="button" [label]="'Opret aftale' | translate" (click)="createAppointment()"></button></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle" [style]="{ width: '50vw' }" [baseZIndex]="10000"></p-confirmDialog>
  <p-toast></p-toast>
  <div class="alert alert-danger" *ngIf="errorMessage">{{ errorMessage }}</div>
</form>

