import { animate, style, transition, trigger } from "@angular/animations";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormControl, FormGroup, ValidationErrors, Validators } from "@angular/forms";
import { ActivityAssignmentDto } from "@apiModels/activityAssignmentDto";
import { ActivityDto } from "@apiModels/activityDto";
import { ActivityService } from "@globals/services/activity.service";
import { UntilDestroy } from "@ngneat/until-destroy";
import { userItems } from "@shared/interfaces-and-enums/shared-data";
import { WindowSessionStorageNames } from "@shared/variables-and-functions/WindowSessionStorageNames";
import { RecurrenceEditorChangeEventArgs } from "@syncfusion/ej2-angular-schedule";
import { L10n, loadCldr, setCulture } from "@syncfusion/ej2-base";
import * as caGregorian from "cldr-data/main/da/ca-gregorian.json";
import * as currencies from "cldr-data/main/da/currencies.json";
import * as numbers from "cldr-data/main/da/numbers.json";
import * as timeZoneNames from "cldr-data/main/da/timeZoneNames.json";
import * as numberingSystems from "cldr-data/supplemental/numberingSystems.json";
import * as weekData from "cldr-data/supplemental/weekData.json";
import moment from "moment";
import { ConfirmationService, MessageService, SelectItem } from "primeng/api";
import { activityTypeItems, categoryItems } from "../shared-data";

@UntilDestroy()
@Component({
  selector: "app-activity-detail",
  templateUrl: "./activity-detail.component.html",
  styleUrls: ["./activity-detail.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [MessageService, ConfirmationService],
  animations: [
    trigger("fadeIn", [transition(":enter", [style({ opacity: 0 }), animate("300ms ease-in", style({ opacity: 1 }))]), transition(":leave", [animate("300ms ease-out", style({ opacity: 0 }))])])
  ]
})
export class ActivityDetailComponent implements OnInit {
  @Input() activityId: number;
  @Input() activity: ActivityDto;
  @Output() closeDialog = new EventEmitter<ActivityDto | null>();

  close(activity: ActivityDto) {
    this.closeDialog.emit(activity);
  }

  public mainForm: FormGroup;
  public showFormErrorMessage: boolean = false;
  public pageTitle = "Prospoect redigering";
  public loading = false;
  public errorMessage = "";
  public selectedActivity: ActivityDto;
  public farmUserItem: SelectItem;
  public recurrenceRule: string = "";
  public graphRecurrenceRule: string = "";

  public farmLeadStatusItem: SelectItem;

  public userId = +window.sessionStorage.getItem(WindowSessionStorageNames.userId);

  plannedStartDate_: Date;
  plannedStartTime_: string;
  plannedEndDate_: Date;
  plannedEndTime_: string;

  timeIntervals: SelectItem[] = [];

  constructor(
    private cd: ChangeDetectorRef,
    private messageService: MessageService,
    private activityService: ActivityService,
    private confirmationService: ConfirmationService
  ) {}

  ngOnInit() {
    loadCldr(numberingSystems, timeZoneNames, weekData, numbers, caGregorian, currencies);

    // Set the culture
    setCulture("da");

    // Load localization data for Syncfusion components
    L10n.load({
      da: {
        recurrenceeditor: {
          // RecurrenceEditor translations
          header: "Gentagelse",
          summary: "Opsummering",
          date: "Dato",
          startTime: "Starttid",
          endTime: "Sluttid",
          repeat: "Gentag",
          cancel: "Annuller",
          save: "Gem",
          ok: "OK",
          never: "Aldrig",
          daily: "Dagligt",
          weekly: "Ugentligt",
          monthly: "Månedligt",
          yearly: "Årligt",
          every: "Hver",
          first: "Første",
          second: "Anden",
          third: "Tredje",
          fourth: "Fjerde",
          last: "Sidste",
          dayOfMonth: "Dag i måneden",
          dayOfWeek: "Dag i ugen",
          repeatEvery: "Gentag hver",
          repeatOn: "Gentag på",
          until: "Indtil",
          occurrences: "Forekomster",
          interval: "Interval",
          advanced: "Avanceret",
          recurrencePattern: "Gentagelsesmønster",
          repeatType: "Gentagelsestype",
          selectDay: "Vælg dag",
          selectWeek: "Vælg uge",
          selectMonth: "Vælg måned",
          selectYear: "Vælg år",
          time: "Tid",
          invalidPattern: "Ugyldigt gentagelsesmønster",
          invalidDate: "Ugyldig dato",
          everyNday: "Hver {0} dag",
          everyNweek: "Hver {0} uge",
          everyNmonth: "Hver {0} måned",
          everyNyear: "Hver {0} år",
          ofEvery: "Hver",
          ofEveryMonth: "hver måned",
          ofEveryYear: "hver år",
          firstDay: "Første dag",
          lastDay: "Sidste dag",
          day: "Dag",
          weekday: "Hverdag",
          weekend: "Weekend"
        }
      }
    });

    if (this.activity && this.activity.id === 0) {
      this.initializeTimeIntervals();
      this.selectedActivity = this.activity;
      this.initializeDateFields();
      this.initFormGroup(true);

      setTimeout(() => {
        this.mainForm.markAsDirty();
        this.cd.detectChanges();
      }, 100);
    } else if (this.activityId) {
      this.activityService.getActivityFromId(this.activityId).subscribe(
        (data: ActivityDto) => {
          this.initializeTimeIntervals();
          this.selectedActivity = data;
          this.initializeDateFields();
          this.initFormGroup(true);
        },
        error => {
          console.error("Error fetching activity data", error);
          this.errorMessage = "Error fetching activity data";
        }
      );
    } else {
      console.error("Selected activity ID is missing");
      this.errorMessage = "Selected activity ID is missing";
    }
  }

  initializeTimeIntervals(): void {
    for (let i = 0; i < 24 * 2; i++) {
      const hours = Math.floor(i / 2)
        .toString()
        .padStart(2, "0");
      const minutes = i % 2 === 0 ? "00" : "30";
      this.timeIntervals.push({ label: `${hours}:${minutes}`, value: `${hours}:${minutes}` });
    }
  }

  initializeDateFields(): void {
    if (this.selectedActivity?.datePlannedStartUtc) {
      const localPlannedStartDate = new Date(moment(this.selectedActivity.datePlannedStartUtc).toLocaleString());
      this.plannedStartDate_ = localPlannedStartDate;
      const hours = localPlannedStartDate.getHours().toString().padStart(2, "0");
      const minutes = localPlannedStartDate.getMinutes().toString().padStart(2, "0");
      this.plannedStartTime_ = `${hours}:${minutes}`;
    } else {
      this.plannedStartDate_ = null;
      this.plannedStartTime_ = "08:00";
    }

    if (this.selectedActivity?.datePlannedEndUtc) {
      const localPlannedEndDate = new Date(moment(this.selectedActivity.datePlannedEndUtc).toLocaleString());
      this.plannedEndDate_ = localPlannedEndDate;
      const hours = localPlannedEndDate.getHours().toString().padStart(2, "0");
      const minutes = localPlannedEndDate.getMinutes().toString().padStart(2, "0");
      this.plannedEndTime_ = `${hours}:${minutes}`;
    } else {
      this.plannedEndDate_ = null;
      this.plannedEndTime_ = "08:00";
    }
  }

  private initFormGroup(makePristine: boolean): void {
    const assignments = this.selectedActivity?.assignments
      ? this.selectedActivity.assignments.map(assignment => ({
          label: assignment.assignedToUser,
          value: assignment.assignedToUserId
        }))
      : [];

    this.mainForm = new FormGroup({
      title: new FormControl(this.selectedActivity?.title || "", [Validators.required]),
      description: new FormControl(this.selectedActivity?.description || ""),
      place: new FormControl(this.selectedActivity?.place || ""),
      //plannedDurationMinutes: new FormControl(this.selectedActivity?.plannedDurationMinutes || 0, [Validators.min(0)]),
      plannedStartDay: new FormControl(this.plannedStartDate_ || null),
      plannedEndDay: new FormControl(this.plannedEndDate_ || null),
      plannedStartTime: new FormControl(this.plannedStartTime_ || ""),
      plannedEndTime: new FormControl(this.plannedEndTime_ || ""),
      dateReminder: new FormControl(this.selectedActivity?.dateReminderUtc ? new Date(this.selectedActivity.dateReminderUtc) : null),
      dateCompleted: new FormControl(this.selectedActivity?.dateCompletedUtc ? new Date(this.selectedActivity.dateCompletedUtc) : null),
      isReminderEnabled: new FormControl(this.selectedActivity?.isReminderEnabled || false),
      createInCalender: new FormControl(this.selectedActivity?.createInCalender || false),
      inTeams: new FormControl(this.selectedActivity?.inTeams || false),
      activityAssignments: new FormControl(assignments),
      categoryId: new FormControl(this.selectedActivity?.categoryId || null),
      activityTypeId: new FormControl(this.selectedActivity?.activityTypeId || null),
      isAllDay: new FormControl(this.selectedActivity?.isAllDay || false) // New form control
    });

    if (this.selectedActivity?.recurrenceRule) {
      this.setRecurrenceRule(this.selectedActivity?.recurrenceRule);
      this.graphRecurrenceRule = this.selectedActivity?.graphRecurrenceRule;
    }

    this.mainForm.get("plannedStartDay").valueChanges.subscribe(startDate => {
      const endDate = this.mainForm.get("plannedEndDay").value;

      if (startDate && endDate && new Date(startDate) > new Date(endDate)) {
        this.mainForm.patchValue({
          plannedEndDay: startDate
        });
      }
    });

    if (makePristine) {
      setTimeout(() => {
        this.mainForm.markAsPristine();
        this.cd.detectChanges();
      }, 100);
    }
  }

  public userItems = userItems;

  public activityTypeItems = activityTypeItems;

  public categoryItems = categoryItems;

  public get title() {
    return this.mainForm.get("title");
  }

  public get description() {
    return this.mainForm.get("description");
  }

  public get place() {
    return this.mainForm.get("place");
  }

  public get plannedDurationMinutes() {
    return this.mainForm.get("plannedDurationMinutes");
  }

  public get plannedStartDay() {
    return this.mainForm.get("plannedStartDay");
  }
  public get plannedStartTime() {
    return this.mainForm.get("plannedStartTime");
  }

  public get plannedEndDay() {
    return this.mainForm.get("plannedEndDay");
  }

  public get plannedEndTime() {
    return this.mainForm.get("plannedEndTime");
  }

  public get dateReminder() {
    return this.mainForm.get("dateReminder");
  }

  public get dateCompleted() {
    return this.mainForm.get("dateCompleted");
  }

  public get isReminderEnabled() {
    return this.mainForm.get("isReminderEnabled");
  }

  public get createInCalender() {
    return this.mainForm.get("createInCalender");
  }

  public get inTeams() {
    return this.mainForm.get("inTeams");
  }

  public get activityAssignments() {
    return this.mainForm.get("activityAssignments");
  }

  public get categoryId() {
    return this.mainForm.get("categoryId");
  }

  public get activityTypeId() {
    return this.mainForm.get("activityTypeId");
  }

  public get isAllDay() {
    return this.mainForm.get("isAllDay");
  }

  // public get recurrence() {
  //   return this.mainForm.get("recurrence");
  // }

  private checkAndValidateForm(): boolean {
    if (this.mainForm.invalid) {
      Object.keys(this.mainForm.controls).forEach(cName => this.mainForm.controls[cName].markAsTouched());
      this.showFormErrorMessage = true;
      return false;
    }
    this.showFormErrorMessage = false;
    return true;
  }

  public getFormValidationErrors() {
    console.log("%c ==>> Validation Errors: ", "color: red; font-weight: bold; font-size:25px;");
    let totalErrors = 0;
    Object.keys(this.mainForm.controls).forEach(key => {
      const controlErrors: ValidationErrors = this.mainForm.get(key).errors;
      if (controlErrors != null) {
        totalErrors++;
        Object.keys(controlErrors).forEach(keyError => {
          console.log("Key control: " + key + ", keyError: " + keyError + ", err value: ", controlErrors[keyError]);
        });
      }
    });
    console.log("Number of errors: ", totalErrors);
  }

  public createPractical() {
    if (!this.selectedActivity) {
      console.error("No selected activity to copy.");
      return;
    }

    const copiedActivity: ActivityDto = JSON.parse(JSON.stringify(this.selectedActivity));

    if (copiedActivity.datePlannedStartUtc) {
      copiedActivity.datePlannedStartUtc = moment(copiedActivity.datePlannedStartUtc).add(3, "days").toISOString();
    }
    if (copiedActivity.datePlannedEndUtc) {
      copiedActivity.datePlannedEndUtc = moment(copiedActivity.datePlannedEndUtc).add(3, "days").toISOString();
    }

    if (copiedActivity.title) {
      copiedActivity.title = copiedActivity.title.replace("Teori undervisning", "Praktik undervisning");
    }
    if (copiedActivity.description) {
      copiedActivity.description = copiedActivity.description.replace("Teori undervisning", "Praktik undervisning");
    }

    copiedActivity.id = 0;
    copiedActivity.activityTypeId = 7; // Praktisk undervisning

    this.selectedActivity = copiedActivity;
    this.initializeDateFields();
    this.initFormGroup(false);

    setTimeout(() => {
      this.mainForm.markAsDirty();
      this.cd.detectChanges();
    }, 100);
  }

  onRecurrenceChange(args: RecurrenceEditorChangeEventArgs): void {
    console.log("Recurrence rule changed", args);
    if (args.value) {
      this.recurrenceRule = args.value;
    } else {
      this.recurrenceRule = "";
    }

    if (this.recurrenceRule && this.plannedStartDay.value) {
      this.graphRecurrenceRule = JSON.stringify(this.convertToGraphRecurrence(this.recurrenceRule, this.plannedStartDay.value), null, 2);
    } else {
      this.graphRecurrenceRule = "";
    }
  }

  // public onChange(args: RecurrenceEditorChangeEventArgs) {
  //   const graphRecurrence = this.convertToGraphRecurrence(args.value, this.plannedStartDate_);
  //   document.getElementById("outlook-patern").innerText = JSON.stringify(graphRecurrence, null, 2);
  // }

  setRecurrenceRule(rule: string): void {
    this.recurrenceRule = rule;
    // The bound RecurrenceEditor will automatically reflect this change
  }

  public onAllDayChange(event: any): void {
    if (event.checked) {
      this.mainForm.patchValue({
        plannedStartTime: "00:00",
        plannedEndTime: "00:00"
      });
    }
  }

  public saveItem() {
    if (!this.checkAndValidateForm()) return;

    this.plannedEndDay.setErrors(null);

    const selectedAssignments = this.mainForm.value.activityAssignments;
    //console.log("Selected Assignments:", selectedAssignments);

    if (selectedAssignments) {
      this.selectedActivity.assignments = selectedAssignments.map(value => {
        return {
          assignedToUserId: value.value,
          assignedToUser: this.userItems.find(item => item.value === value.value).label // Safely access label
        } as ActivityAssignmentDto;
      });
    } else {
      this.selectedActivity.assignments = [];
    }

    let plannedStartString = null;

    if (this.plannedStartDay) {
      const plannedStartDate = new Date(this.plannedStartDay.value);
      if (this.plannedStartTime.value) {
        const [hours, minutes] = this.plannedStartTime.value.split(":").map(num => parseInt(num, 10));
        plannedStartDate.setHours(hours, minutes, 0, 0);
      }
      plannedStartString = plannedStartDate.toISOString();
    }

    let plannedEndString = null;

    if (this.plannedEndDay) {
      const plannedEndDate = new Date(this.plannedEndDay.value);
      if (this.plannedEndTime.value) {
        const [hours, minutes] = this.plannedEndTime.value.split(":").map(num => parseInt(num, 10));
        plannedEndDate.setHours(hours, minutes, 0, 0);
      }
      plannedEndString = plannedEndDate.toISOString();
    }

    // Validate that planned end date is after planned start date
    if (plannedStartString && plannedEndString) {
      const plannedStartDate = new Date(plannedStartString);
      const plannedEndDate = new Date(plannedEndString);

      if (plannedEndDate < plannedStartDate) {
        this.plannedEndDay.setErrors({ dateInvalid: "Start must not be after end" });
        return;
      }
    }

    const dateReminder = this.dateReminder;
    const dateCompleted = this.dateCompleted;

    if (dateReminder && dateReminder.value) {
      this.selectedActivity.dateReminderUtc = new Date(dateReminder.value).toISOString();
    } else {
      this.selectedActivity.dateReminderUtc = null;
    }

    if (dateCompleted && dateCompleted.value) {
      this.selectedActivity.dateCompletedUtc = new Date(dateCompleted.value).toISOString();
    } else {
      this.selectedActivity.dateCompletedUtc = null;
    }

    const excludeKeys = ["activityAssignments", "plannedStartDay", "plannedEndDay", "plannedStartTime", "plannedEndTime", "dateReminder", "dateCompleted"];

    Object.keys(this.mainForm.controls).forEach(key => {
      if (!excludeKeys.includes(key)) {
        const controlValue = this.mainForm.get(key).value;
        if (controlValue && typeof controlValue === "object" && controlValue.hasOwnProperty("value")) {
          this.selectedActivity[key] = controlValue.value;
        } else {
          this.selectedActivity[key] = controlValue;
        }
      }
    });

    this.selectedActivity.datePlannedStartUtc = plannedStartString;
    this.selectedActivity.datePlannedEndUtc = plannedEndString;
    this.selectedActivity.recurrenceRule = this.recurrenceRule;
    this.selectedActivity.graphRecurrenceRule = this.graphRecurrenceRule;

    this.activityService.updateActivity(this.selectedActivity).subscribe({
      next: () => {
        this.messageService.add({
          severity: "success",
          summary: "Success",
          detail: "Data blev gemt"
        });

        this.close(this.selectedActivity);
      },
      error: err => {
        console.error("Error saving activity data", err);
        this.errorMessage = "Error saving activity data";
      }
    });
  }

  public deleteItem() {
    this.confirmationService.confirm({
      message: "Ønsker du at slette " + this.selectedActivity.title + " ?",
      header: "Bekræft sletning",
      icon: "pi pi-info-circle",
      acceptButtonStyleClass: "p-button-danger p-button-text",
      rejectButtonStyleClass: "p-button-text p-button-text",
      acceptIcon: "none",
      rejectIcon: "none",
      acceptLabel: "Ja",
      rejectLabel: "Nej",
      accept: () => {
        this.selectedActivity.deleted = true;
        this.activityService.updateActivity(this.selectedActivity).subscribe({
          next: () => {
            this.messageService.add({
              severity: "success",
              summary: "Success",
              detail: "Data blev gemt"
            });

            this.close(this.selectedActivity);
          },
          error: err => {
            console.error("Error saving activity data", err);
            this.errorMessage = "Error saving activity data";
          }
        });
      },
      reject: () => {
        // Do nothing or handle rejection
      }
    });
  }

  public parseRecurrenceRule(recurrenceRule) {
    const parts = recurrenceRule.split(";");
    let rule = {};

    parts.forEach(part => {
      const [key, value] = part.split("=");
      rule[key] = value;
    });

    return rule;
  }

  public convertToGraphRecurrence(recurrenceRule, startDate) {
    const rule = this.parseRecurrenceRule(recurrenceRule);
    let graphRecurrence = {
      pattern: {},
      range: {}
    };

    switch ((rule as any).FREQ) {
      case "DAILY":
        graphRecurrence.pattern = {
          type: "daily",
          interval: parseInt((rule as any).INTERVAL, 10)
        };
        break;

      case "WEEKLY":
        graphRecurrence.pattern = {
          type: "weekly",
          interval: parseInt((rule as any).INTERVAL, 10),
          daysOfWeek: (rule as any).BYDAY.split(",").map(day => this.dayMap(day))
        };
        break;

      case "MONTHLY":
        if ((rule as any).BYMONTHDAY) {
          graphRecurrence.pattern = {
            type: "absoluteMonthly",
            interval: parseInt((rule as any).INTERVAL, 10),
            dayOfMonth: parseInt((rule as any).BYMONTHDAY, 10)
          };
        } else {
          graphRecurrence.pattern = {
            type: "relativeMonthly",
            interval: parseInt((rule as any).INTERVAL, 10),
            daysOfWeek: (rule as any).BYDAY.split(",").map(day => this.dayMap(day)),
            index: this.getIndexString((rule as any).BYSETPOS)
          };
        }
        break;

      case "YEARLY":
        if ((rule as any).BYMONTHDAY) {
          graphRecurrence.pattern = {
            type: "absoluteYearly",
            interval: parseInt((rule as any).INTERVAL, 10),
            dayOfMonth: parseInt((rule as any).BYMONTHDAY, 10),
            month: parseInt((rule as any).BYMONTH, 10)
          };
        } else {
          graphRecurrence.pattern = {
            type: "relativeYearly",
            interval: parseInt((rule as any).INTERVAL, 10),
            daysOfWeek: (rule as any).BYDAY.split(",").map(day => this.dayMap(day)),
            month: parseInt((rule as any).BYMONTH, 10),
            index: this.getIndexString((rule as any).BYSETPOS)
          };
        }
        break;
    }

    if ((rule as any).COUNT) {
      graphRecurrence.range = {
        type: "numbered",
        startDate: startDate,
        numberOfOccurrences: parseInt((rule as any).COUNT, 10)
      };
    } else if ((rule as any).UNTIL) {
      graphRecurrence.range = {
        type: "endDate",
        startDate: startDate,
        endDate: this.formatUntilDate((rule as any).UNTIL)
      };
    } else {
      graphRecurrence.range = {
        type: "noEnd",
        startDate: startDate
      };
    }

    return graphRecurrence;
  }

  public formatUntilDate(isoDate: any) {
    return isoDate.substring(0, 8).replace(/(\d{4})(\d{2})(\d{2})/, "$1-$2-$3");
  }

  public getIndexString(bySetPos: any) {
    const indexMap = {
      "1": "first",
      "2": "second",
      "3": "third",
      "4": "fourth",
      last: "last"
    };
    return indexMap[bySetPos] || "first";
  }

  public dayMap(day: any) {
    const dayMapping = {
      SU: "Sunday",
      MO: "Monday",
      TU: "Tuesday",
      WE: "Wednesday",
      TH: "Thursday",
      FR: "Friday",
      SA: "Saturday"
    };
    return dayMapping[day];
  }
}
